@import 'leaflet/dist/leaflet.css';


#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 50px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger {
  height: 32px;
  background: #ffffff33;
  margin: 16px;
}

.demo-logo {
  background: rgba(19, 3, 160, 0.89);
}
.site-layout .site-layout-background {
  background: #fff;
}

.titulo-registro {
  color: rgba(28, 12, 173, 0.89);
}
.ant-layout-header {
  margin: 0px 15px !important;
  padding: 0 27px;
}

.even-row {
  background-color: #f0f0f0; /* Color de fondo para filas pares */
  height: 2px;
}

.odd-row {
  background-color: #bfbfbf; /* Color de fondo para filas impares */
  height: 2px;
}

.highlight-row {
  background-color: #fff9c4 !important; /* Light yellow */
}

.center-cell {
  text-align: center;
}

.warning-content {
  text-align: center;
}

h5 {
  margin-top: 9px !important;
}
.ant-image-img {
  cursor: pointer !important;
}
.ant-popover-title {
  text-align: center;
  text-transform: uppercase;
}

.noheader-popover .ant-popover-title {
  display: none;
}

.radio-group-container {
  display: flex;
  flex-wrap: wrap;
}

.radio-group-container .ant-radio-button-wrapper {
  width: 33.33%;
}

/* YourComponent.css */
.stats-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.charts-container {
  display: flex;
    flex-direction: row;
  width: 100%;
  height: 100%;
  min-height: 300px;
}

.text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 100px;
}

.no-border-button {
  background-color: transparent;
  border: none;
  padding: 0; /* Remove any padding */
  box-shadow: none; /* Remove focus shadow, if any */
}

.no-border-button:hover,
.no-border-button:focus {
  background-color: transparent; /* Prevent hover effects */
  outline: none; /* Remove focus outline */
}


.wait_times {
  font-size: small;
}

/* This rule changes the background color of the unselected radio buttons on hover */
.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked):hover {
  background-color: #f5f5f5; /* Replace with your desired color */
}

